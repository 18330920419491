
import axios from 'axios';

import { defineStore } from 'pinia';

export const useUserStore = defineStore( 'user', { // eslint-disable-line snakecasejs/snakecasejs
	state: () => {
		return {
			session_data: null,
			craft_user_data: null,
			// Period picker should reset when session ends to avoid UI
			// confusion - storing it here therefore seems to make the most
			// sense.
			displayed_period: undefined, // We want the default prop value for this.
		};
	},
	actions: {
		async loadSession() {
			const response = await axios.get( '/actions/users/session-info', {
				timeout: 5000,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			} );
			this.session_data = response.data;
			this.session_data.timeout_date = Date.now() + ( response.data.timeout * 1000 );
			this.populateCraftUserData();
		},
		async assertSession() {
			if (
				this.session_data
				&& this.session_data.id
				&& this.session_data.timeout_date > Date.now()
			) {
				return;
			}
			await this.loadSession();
			if ( this.session_data.isGuest ) {
				this.logOut( 'members/login' );
			}
		},
		async populateCraftUserData() {
			if (
				!this.session_data
				|| !this.session_data.id
			) {
				return;
			}
			const response = await axios.get(
				'/actions/iom/member-account/get-current-user-data',
				{
					timeout: 5000,
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					}
				} );
			this.craft_user_data = response.data;
		},
		async logOut( redirect = '' ) {
			await axios.get( '/actions/users/logout', {
				timeout: 5000,
			} );
			this.$reset();
			window.location = '/' + redirect;
		},
	},
} );
